import { http } from './http-common'

export const getUsers = (payload) => {
  return http.get('/api/admin/customers/getCustomers', { params: payload })
}
export const getDrivers = (payload) => {
  return http.get('/api/admin/drivers/getDrivers', { params: payload })
}

export const getUsersReviews = (payload) => {
  return http.get('/api/admin/reviews/getReviews', { params: payload })
}

export const getCustomer = (id, payload) => {
  return http.get(`api/admin/customers/getCustomer/${id}`, { params: payload })
}
export const getCustomerVehicle = (id, payload) => {
  return http.get(`api/admin/customers/getCustomerVehicle/${id}`, {
    params: payload,
  })
}

export const getDriverVehicle = (id, payload) => {
  return http.get(`api/admin/drivers/getDriverVehicle/${id}`, {
    params: payload,
  })
}

export const getDriver = (id, payload) => {
  return http.get(`api/admin/drivers/getDriver/${id}`, { params: payload })
}

export const approveUser = (id, payload) => {
  return http.get(`api/admin/customers/approveCustomer/${id}`, {
    params: payload,
  })
}

export const blockUser = (id, payload) => {
  return http.get(`api/admin/customers/blockCustomer/${id}`, {
    params: payload,
  })
}

export const approveDriver = (id, payload) => {
  return http.get(`api/admin/drivers/approveDriver/${id}`, { params: payload })
}

export const blockDriver = (id, payload) => {
  return http.get(`api/admin/drivers/disapproveDriver/${id}`, {
    params: payload,
  })
}

export const deleteUser = (id, payload) => {
  return http.get(`api/admin/customers/deleteCustomer/${id}`, {
    params: payload,
  })
}

export const deleteDriver = (id, payload) => {
  return http.get(`api/admin/drivers/deleteDriver/${id}`, { params: payload })
}

export const createUser = (payload) => {
  return http.post('/users', payload)
}
export const getUserSingle = (id) => {
  return http.get(`/users/${id}`)
}
export const getUserSingleMetadata = (id) => {
  return http.get(`/professionals_featured/${id}`)
}
export const getAllUserWithVipCode = (code) => {
  return http.get(`/getAllUserWithVipCode/${code}`)
}
export const updateFeatured = (payload) => {
  return http.put(`/update_professionals_featured/${payload.user_id}`, payload)
}
export const getInvitedUsers = (id) => {
  return http.get(`/getInvitedUsers/${id}`)
}
export const getInvitedUsersCount = (id) => {
  return http.get(`/getInvitedUsersCount/${id}`)
}
export const getIsUserMakeFirstBooking = (id) => {
  return http.get(`/isUserMakeFirstBooking/${id}`)
}
export const getUserEarning = (payload) => {
  return http.get(`/earning/${payload.id}`, { params: payload })
}
export const getSingleUserMeetingStats = (payload) => {
  return http.get(`/bookings/${payload.id}/detail`, { params: payload })
}
export const getUserActivity = (id) => {
  return http.get(`/notifications_history/${id}`)
}

export const getEducationByID = (id) => {
  return http.get(`/education/${id}`)
}

export const updateUserEducation = (id, payload) => {
  return http.put(`/education/${id}`, payload)
}
export const getExperienceByID = (id) => {
  return http.get(`/experience/${id}`)
}
export const updateUserExperience = (id, payload) => {
  return http.put(`/experience/${id}`, payload)
}
export const createUserEducation = (payload) => {
  return http.post('/education', payload)
}
export const createUserExperience = (payload) => {
  return http.post('/experience', payload)
}
export const deleteUserEducation = (id) => {
  return http.delete(`/education/${id}`)
}
export const deleteUserExperience = (id) => {
  return http.delete(`/experience/${id}`)
}

export const getUsersStats = (payload) => {
  return http.get('/api/admin/customers/getUsersDashboardStats', {
    params: payload,
  })
}

export const getDriversDashboardStats = (payload) => {
  return http.get('/api/admin/drivers/getDriversDashboardStats', {
    params: payload,
  })
}
