<template>
  <v-container>
    <v-row>
      <div class="tabs-div">
        <TabNavigation :tabs="tabs" :currentDispatcherId="this.$route.params.id"/>
      </div>
    </v-row>

    <div class="drivers-table">

      <v-row justify="start" style="">
        <v-col cols="6">
          <v-btn-toggle v-model="filters.status" color="primary" style="">
            <v-btn text small style="height: 50px" value="All"> All </v-btn>
            <v-btn text small style="height: 50px" value="active"> Active </v-btn>
            <v-btn text small style="height: 50px" value="pending"> Pending </v-btn>
            <v-btn text small style="height: 50px" value="blocked"> Blocked </v-btn>
          </v-btn-toggle>
        </v-col>

        <v-col cols="6" style="margin-top: 8px">
          <v-row>
            <div style="max-width: 300px; margin-right: 10px;">
              <date-filter :init-date="initDate" @dateChange="onDateChange" />
            </div>
            
            <v-text-field v-model="filters.search" label="Search Drivers" solo append-icon="mdi-magnify" style="width: 200px; margin-right: 10px;" />

            <!-- <v-btn color="primary" class="ml-4" @click="openAddDispatcherDialog" style="width: 95px; height: 47px; margin-left: 5px">
              <v-icon small>mdi-plus</v-icon>
              Add New
            </v-btn> -->
          </v-row>
        </v-col>
      </v-row>

      <!-- <add-update-dispatcher-dialog @dispatcherAdded="fetchDispatcherDrivers" :dispatcherId="selectedDispatcherId" @close="modalAbruptlyClosed" ref="addUpdateDispatcherDialog" /> -->

      <v-card class="mt-15">
        <base-material-card color="primary">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Drivers
            </div>
          </template>
          <v-data-table
            :headers="headers"
            :items="drivers"
            :loading="loading"
            :server-items-length="totalItems"
            :options.sync="tableOptions"
            :footer-props="{
              'items-per-page-options': tableOptions.rowsPerPageItems,
            }"
            class="elevation-1"
          >

              <template v-slot:[`item.isVerified`]="{ item }">
                <v-chip class="ma-2" label outlined small :color="getColor(item)">
                  {{ item.banned ? "BLOCKED" : (item.isVerified ? "ACTIVE" : "PENDING")
                  }} </v-chip>
              </template>

              <template v-slot:[`item.createdAt`]="{ item }">
                {{ getReadableDate(item.createdAt) }}
              </template>
              
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom v-if="!item.deletedAt">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="mr-2" v-bind="attrs" v-on="on" @click="goToProfile(item._id)">
                      mdi-eye
                    </v-icon>
                  </template>
                  <span>View</span>
                </v-tooltip>

                <v-tooltip bottom v-if="!item.deletedAt">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="mr-2" v-bind="attrs" v-on="on" @click="toggleVerification(item._id, item.isVerified)">
                      {{ item.isVerified ? 'mdi-account-check' : 'mdi-account-off' }}
                    </v-icon>
                  </template>
                  <span>{{ item.isVerified ? 'Disapprove' : 'Approve' }}</span>
                </v-tooltip>

                <v-tooltip bottom v-if="!item.deletedAt">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="mr-2" v-bind="attrs" v-on="on" @click="toggleBanned(item._id, item.banned)">
                      {{ item.banned ? 'mdi-account-lock' : 'mdi-account' }}
                    </v-icon>
                  </template>
                  <span>{{ item.banned ? 'Unblock' : 'Block' }}</span>
                </v-tooltip>

                <v-tooltip bottom v-if="!item.deletedAt">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="mr-2" v-bind="attrs" v-on="on" @click="deleteUser(item._id)">
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </template>
          </v-data-table>
        </base-material-card>
      </v-card>
          
   </div>
  </v-container>
</template>

<script>
import {
  updateDispatcher,
  getDispatcherDrivers,
  deleteDispatcher,
  deleteDispatcherDriver,
} from "@/services/admin";


import {
  deleteDriver,
} from "@/services/users";

import DateFilter from "@/components/dateFilter";
import AddUpdateDispatcherDialog from '@/components/addDispatcherForm';
import TabNavigation from '@/components/TabNavigation.vue';

function debounce(func, delay) {
  let timer;
  return function (...args) {
    const context = this;
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
}

export default {
  components: { DateFilter, AddUpdateDispatcherDialog, TabNavigation },
  data() {
    return {

      tabs: [
        { name: 'General', path: '/dispatcher-general-details' },
        { name: 'Bookings', path: '/dispatcher-bookings' },
        { name: 'Drivers', path: '/dispatcher-drivers' },
        { name: 'Vehicles', path: '/dispatcher-vehicles' },
        { name: 'Transactions', path: '/dispatcher-transactions' }
      ],

      loading: false,
      drivers: [],

      filters: {
        startDate: null,
        endDate: null,
        status: "All",
        search: '',
        order: 'DESC'
      },
      statusOptions: ['All', 'Active', 'Pending', 'Incomplete', 'Blocked', 'Deleted'],

      headers: [
        { text: 'First Name', value: 'firstName' },
        { text: 'Last Name', value: 'surName' },
        { text: 'Phone', value: 'phone' },
        { text: 'Email', value: 'email' },
        { text: 'Status', value: 'isVerified' },
        { text: 'Created At', value: 'createdAt' },

        {
          text: "Action",
          align: "start",
          sortable: false,
          value: "actions",
        },
      ],

      tableOptions: {
        page: 1,
        itemsPerPage: 10,
        totalItems: 0,
        rowsPerPageItems: [10, 30, 50, 100],
      },

      totalItems: 10,

      initDate: [],
      usersStatus: "all",
      stats: null,
      selectedDispatcherId: null

    };
  },
  created() {
    this.fetchDispatcherDrivers();
    // this.fetchDispatcherStats();
  },
  watch: {
    'tableOptions': {
      handler: 'fetchDispatcherDrivers',
      deep: true
    },

    // usersStatus() {
    //   this.filters.status = this.usersStatus
    //   this.fetchDispatcherDrivers();
    // },

    'filters.status': {
      handler(newStatus, oldStatus) {
        if (newStatus !== oldStatus) {
          this.fetchDispatcherDrivers();
        }
      },
      deep: true
    },

    'filters.search': {
      handler: debounce(async function (newValue) {
        this.fetchDispatcherDrivers();
      }, 500),
      immediate: false
    },
  },
  methods: {
    async fetchDispatcherDrivers() {
      try {
        this.loading = true;
        const response = await getDispatcherDrivers({
          ...this.tableOptions,
          ...this.filters,
          take: this.tableOptions.itemsPerPage,
          status: this.filters.status === 'All' ? null : this.filters.status
        }, this.$route.params.id);

        this.drivers = response.data.data;
        this.totalItems = response.data.meta.itemCount;
      } catch (error) {
        console.error('Error fetching drivers:', error);
      } finally {
        this.loading = false;
      }
    },

    async toggleVerification(dispatcherId, isVerified) {
      try {
        const updatedDispatcher = { isVerified: !isVerified };
        await updateDispatcher(dispatcherId, updatedDispatcher);
        this.fetchDispatcherDrivers();
        // this.fetchDispatcherStats();
      } catch (error) {
        console.error('Error toggling verification status:', error.message);
      }
    },

    async toggleBanned(dispatcherId, isBanned) {
      try {
        const updatedDispatcher = { banned: !isBanned };
        await updateDispatcher(dispatcherId, updatedDispatcher);
        this.fetchDispatcherDrivers();
        // this.fetchDispatcherStats();
      } catch (error) {
        console.error('Error toggling banned status:', error.message);
      }
    },

    onDateChange(newValue) {
      // console.log('newValue', newValue);
      if (!newValue || newValue.length !== 2) return;

      // Convert the strings to Date objects
      const startDate = newValue[0] ? new Date(newValue[0]) : null;
      const endDate = newValue[1] ? new Date(newValue[1]) : null;

      // Update the filters with the new start and end dates
      this.filters.startDate = startDate ? startDate.toISOString().split('T')[0] : '';
      this.filters.endDate = endDate ? endDate.toISOString().split('T')[0] : '';

      // Fetch drivers with the updated filters
      this.fetchDispatcherDrivers();
      // this.fetchDispatcherStats();
    },

    async deleteUser(id) {
      const confirmDelete = window.confirm('Are you sure you want to delete this dispatcher?');

      if (confirmDelete) {
        try {
          await deleteDispatcherDriver(id);
          this.fetchDispatcherDrivers();
        } catch (error) {
          console.error('Error deleting user:', error.message);
          if (error.response && error.response.data && error.response.data.message) {
            window.alert('Failed to delete driver: ' + error.response.data.message);
          } else {
            window.alert('Failed to delete driver. Please try again later.');
          }
        }
      }
    },

    getColor(item) {
      return item.banned ? "red" : item.isVerified ? "green" : "orange"
    },

    getReadableDate(date) {
      const d = new Date(date);
      return d.toUTCString();
    },

    goToProfile(id) {
      this.$router.push({ name: "Driver Profile", params: { id: id } });
    },
  },


};
</script>

<style scoped>
.tabs-div {
  width: 100%;
  padding: 20px;
}

.drivers-table {
  width: 100%;
  padding: 0.7rem;
}
</style>